import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      frontcreative: file(
        relativePath: { eq: "featuredphotos/00-RebelDoughnuts_Minis-Edit.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      frontstill: file(
        relativePath: { eq: "featuredphotos/02-HoneyAndSaltCakeDesign-208.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      frontonlocation: file(
        relativePath: { eq: "featuredphotos/01-DesertFlower-Feb13-7132.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      title="Your Brand Content Creator"
      subtitle="Pro Photos for online and print content"
    >
      <SEO
        title="Your creative for your brands photos and videos."
        keyWords={[
          `Social Media Content Creator`,
          `Social Media Agency`,
          `Albuquerque`,
          `Albuquerque New Mexico`,
          `Product Photography`,
          `Rio Rancho, NM`,
          `Food Photography`,
          `Brand Content`,
          `ABQ Local`,
          `Comercial Photography`,
          `Soona Albuquerque`,
        ]}
      />
      <div className="indexPhotoStyles">
        <div className="indexSection leftside">
          <div className="indexSectionsText">
            <h2>Creative</h2>
            <p>
              Add a little spice to your brand content, whether that's with
              floating products or action shots. Give your products a creative
              head start when it comes to your brand.
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <Img
              fluid={data.frontcreative.childImageSharp.fluid}
              alt="Food Photography photo of floating colorful dounuts on a bright baground."
            />
          </div>
        </div>
        <div className="indexSection rightside">
          <div className="indexSectionsText">
            <h2>Still Life</h2>
            <p>
              Brand-crafted scenes in the studio for your products, matching
              your creative style and aesthetic. Make your next ad campaign
              cohesive and on-point to hit your target audience.
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <Img
              fluid={data.frontstill.childImageSharp.fluid}
              alt="Still life photo of Rassberry Cake that has a slice being taken out."
            />
          </div>
        </div>

        <div className="indexSection leftside">
          <div className="indexSectionsText">
            <h2>On-Location</h2>
            <p>
              Get awesome photos of your dishes or products with you and your
              team members. Share the experience of your restaurant, business,
              or creative workspace.
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <Img
              fluid={data.frontonlocation.childImageSharp.fluid}
              alt="Photo of a Flower Shop owner handing a buquet of flowers to a customer."
            />
          </div>
        </div>
      </div>
      <div className="ctaIndex">
        <a href="/book/" className="button">
          Book Your Session
        </a>
      </div>
    </Layout>
  )
}
